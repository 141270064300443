import * as React from 'react';
import {useSelector} from 'react-redux';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import {Link as RouterLink} from "react-router-dom";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {ReactComponent as UserIcon} from "images/User_Notification.svg"
import {routes} from "routes";
import ContactFormModal from "components/organisms/ContactFormModal/ContactFormModal";
import Button from "@mui/material/Button";
import {useAuth0} from "@auth0/auth0-react";
import Divider from "@mui/material/Divider";

export default function UserMenu({mobile}) {
  const { loginWithRedirect, logout } = useAuth0();
  const isAuthorised = useSelector(state => state.global.isAuthorised);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box sx={{ display: 'flex', p: mobile? 0 : 2 }}>

      {!mobile && <Box sx={{width: "40%"}}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined" size="small">
          <InputLabel htmlFor="search">Type strategy name, ticket, instrument (e.g. futures), or term</InputLabel>
          <OutlinedInput
            id="search"
            type={"text"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Type strategy name, ticket, instrument (e.g. futures), or term"
          />
        </FormControl>
      </Box>
      }

      <Box sx={{ flexGrow: 1 }} />

      {isAuthorised ?
      <Box>

         {mobile && <Tooltip title="Search">
          <IconButton size="small" sx={{ mr: 1 }} color="inherit" >
             <SearchIcon />
          </IconButton>
        </Tooltip>}

         <ContactFormModal mobile={mobile}/>

        <Tooltip title="Account Settings">
          <IconButton onClick={handleClick} size="small" color="inherit">
            {mobile ?  <AccountCircle />:  <UserIcon width="48px"/>}
          </IconButton>
        </Tooltip>

        <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose}  >
          <MenuItem component={RouterLink} to={routes.account} onClick={handleClose}> General Account Settings </MenuItem>
          <Divider />
          <MenuItem onClick={() => {logout({redirectTo: routes.leaderBoard}) ; handleClose()}} > Log Out </MenuItem>

        </Menu>
      </Box>:

      <Box sx={{mt: 2, mr: 1}}>
          <Button color="secondary" variant="contained" onClick={() => loginWithRedirect()}>Login</Button>&nbsp;&nbsp;
          <Button color="secondary" variant="contained" onClick={() => loginWithRedirect({authorizationParams: { screen_hint: "signup", }})} disabled>Sign Up</Button>
      </Box>
      }

    </Box>
  )
}
