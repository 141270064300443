class WebSocketManager {
  constructor() {
    this.socket = null;
    this.messageCallback = null;
    this.heartbeatInterval = null;
    this.onOpenCallback = null;
    this.onCloseCallback = null;
    this.onErrorCallback = null;
  }

  async connect(accessToken, conids) {
    if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
      console.log("WebSocket already connected or connecting");
      return;
    }

    const conidParam = conids.join(',');
    this.socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws/md/?token=${accessToken}&conid=${conidParam}`);
    
    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      this.startHeartbeat();
      if (this.onOpenCallback) {
        this.onOpenCallback();
      }
    };

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message:', message);

      if (this.messageCallback) {
        this.messageCallback(message);
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      if (this.onErrorCallback) {
        this.onErrorCallback(error);
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
      this.stopHeartbeat();
      if (this.onCloseCallback) {
        this.onCloseCallback(event);
      }
    };
  }

  onMessage(callback) {
    this.messageCallback = callback;
  }

  onOpen(callback) {
    this.onOpenCallback = callback;
  }

  onClose(callback) {
    this.onCloseCallback = callback;
  }

  onError(callback) {
    this.onErrorCallback = callback;
  }

  startHeartbeat() {
    if (!this.heartbeatInterval) {
      console.log("Starting heartbeat...");
      this.heartbeatInterval = setInterval(() => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          console.log("Sending ping");
          this.socket.send(JSON.stringify({ type: 'ping' }));
        } else {
          this.stopHeartbeat();
        }
      }, 30000);
    }
  }

  stopHeartbeat() {
    if (this.heartbeatInterval) {
      console.log("Stopping heartbeat...");
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  disconnect() {
    if (this.socket) {
      console.log("Closing WebSocket connection");
      this.stopHeartbeat();
      this.socket.close();
      this.socket = null;
    }
  }
}

const webSocketManager = new WebSocketManager();
export default webSocketManager;
