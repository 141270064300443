import React from 'react'
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import {useAuth0} from "@auth0/auth0-react";
import authAxios from "utils/authAxios";
import CircularProgress from '@mui/material/CircularProgress';
import {setAuthorised} from "redux/global/globalSlice";
import {Box} from "@mui/system";
import Logo from "images/TPP_Logo_Rev.svg";
import {fetchUserProfile} from "redux/userProfile/userProfileSlice";
import Typography from "@mui/material/Typography";

const AuthProvider = ({children}) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, error: auth0Error } = useAuth0();
  const dispatch = useDispatch();
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const userProfile = useSelector(state => state.userProfile.data);
  const [authError, setAuthError] = useState(null);

  const initializeAuth = useCallback(async () => {
    if (!isAuthenticated) return;
    
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_BACKEND_URL
        },
        detailedResponse: true
      });
      
      if(!_.isEmpty(accessToken)) {
        authAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken.access_token}`;
        dispatch(setAuthorised(true));
        await dispatch(fetchUserProfile());
      }
    } catch (error) {
      console.error('Auth initialization error:', error);
      setAuthError(error);
      
      // If the error is due to token expiration, try to refresh
      if (error.error === 'login_required' || error.error === 'consent_required') {
        await getAccessTokenSilently({ ignoreCache: true });
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    let isMounted = true;

    const setupAuth = async () => {
      if (!isMounted) return;
      await initializeAuth();
    };

    setupAuth();
    
    return () => {
      isMounted = false;
    };
  }, [initializeAuth]);

  // Show Auth0 errors if any
  if (auth0Error) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#181720' }}>
        <Typography color="error">
          {auth0Error.message || 'Authentication error. Please try refreshing the page.'}
        </Typography>
      </Box>
    );
  }

  // Show our custom auth errors
  if (authError) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#181720' }}>
        <Typography color="error">
          {authError.message || 'Authentication error. Please try refreshing the page.'}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isLoading || (isAuthenticated && !isAuthorised) || (isAuthorised && userProfile === null) ? (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#181720' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Logo} alt="Portfolio Platform" width={160} style={{ marginLeft: "8px", marginRight: "8px", marginBottom: "20px" }} />
            <CircularProgress color="secondary" />
          </Box>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default AuthProvider;
