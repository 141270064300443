import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {styled} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from "images/Dashboard_Rev.svg";
import WatchlistIcon from "images/Watchlist_Rev.svg";
import StrategiesIcon from "images/Strategies_Rev.svg";
import MyStrategiesIcon from "images/My_Strategies_Rev.svg";
import ToolsIcon from "images/Tools_Rev.svg";
import BrokerIcon from "images/Broker_Rev.svg";
import AutotraderIcon from "images/AutoTrader_Rev.svg";
import LogoutIcon from "images/Logout_Rev.svg";
import {Link as RouterLink} from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {routes} from "routes";
import {selectOwnedStrategies} from "redux/strategy/strategySlice";
import {setMenuClose, setMenuOpen} from "redux/global/globalSlice";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from "@mui/material/Tooltip";


const ListItemButtonStyles = (open) => ({
  minHeight: 48, px: 2.5, paddingTop: 0, paddingBottom: 0
})

const ListItemIconStyles = (open) => ({
  minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center',
  '& img' : { width: "49px", marginLeft: "-13px" }
})


const MenuDivider = styled(Divider)(
  ({theme}) => ({
    borderColor: theme.palette.light.main,
    margin: '0 20px 0 20px',
    opacity: '0.3'
  }),
)


const SubMenu = styled(Collapse)(
  ({theme}) => ({
    '& span' : {
      paddingLeft: '48px',
      opacity: "0.6"
    },
    '& .MuiListItemIcon-root' : {
      paddingLeft: '48px',
      marginRight: '-45px',

    }
  }),
)

const defaultExpandedState = {tools: false, autotrade: false}


export default function MainMenuList({open, mobile}) {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState({...defaultExpandedState});
  const ownedStrategies = useSelector(selectOwnedStrategies);
  const isAuthorised = useSelector(state => state.global.isAuthorised);

  useEffect(() => {
    if(!open) setIsExpanded({...defaultExpandedState})
  }, [open]);


  const handleToggleExpansion = (item) => {
    dispatch(setMenuOpen())
    setIsExpanded({...isExpanded, [item] : !isExpanded[item]});

  };

  const handleLinkClick = () => {
    if(mobile) dispatch(setMenuClose())
  }

  return (
      <List sx={{"height": "100%"}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: "100%"}}>
          <Box >
          { isAuthorised && <>
              <ListItem key={"dashboard"} disablePadding sx={{ display: 'block' }} >
                <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick}  component={RouterLink} to={routes.dashboard}>
                  <ListItemIcon sx={ListItemIconStyles(open)} >
                      <img src={DashboardIcon}/>
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <MenuDivider />
            </>}

          { isAuthorised && <>
          <ListItem key={"watch-list"} disablePadding sx={{ display: 'block' }}>
          <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick} component={RouterLink} to={routes.watchList}>
            <ListItemIcon sx={ListItemIconStyles(open)} >
                <img src={WatchlistIcon}/>
            </ListItemIcon>
            <ListItemText primary={"My Watch List"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
          </ListItem>
          <MenuDivider />
          </>}

          <ListItem key={"strategies"} disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick} component={RouterLink} to={routes.leaderBoard}   >
              <ListItemIcon sx={ListItemIconStyles(open)} >
                <img src={StrategiesIcon}/>
              </ListItemIcon>
              <ListItemText primary={"Discover Strategies"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <MenuDivider />

          { isAuthorised && <>

          <ListItem key={"my-strategies"} disablePadding onClick={() => handleToggleExpansion("my-strategies")}>
            <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick} component={RouterLink}>
              <ListItemIcon sx={ListItemIconStyles(open)} >
                <img src={MyStrategiesIcon}/>
              </ListItemIcon>
              <ListItemText primary={"My Strategies"} sx={{ opacity: open ? 1 : 0 }} />
              <Box sx={{ opacity: open ? 1 : 0 }}>
                {isExpanded["my-strategies"] ? <ExpandLessIcon  color="success" /> : <ExpandMoreIcon color="success" />}
              </Box>
            </ListItemButton>
          </ListItem>
            <SubMenu in={isExpanded["my-strategies"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={handleLinkClick} component={RouterLink} to={routes.strategyFormCreate}>
                  <ListItemIcon size="xs">
                    <AddOutlinedIcon fontSize={"small"} color="success" />
                  </ListItemIcon>
                  <ListItemText primary="Create Strategy" />
                </ListItemButton>
              </List>
            </SubMenu>

            {ownedStrategies.map((strategy, index) => (
                <SubMenu key={`my-strategies-${index}`} in={isExpanded["my-strategies"]} timeout="auto"  unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleLinkClick} component={RouterLink} to={routes.strategyDetails.replace(":strategyId", strategy.id)} style={{opacity: strategy.public? '1': '0.6'}}>
                      <ListItemText primary={strategy.name} />
                      {!strategy.public && <Tooltip title="Private" placement="top"><VisibilityOffIcon fontSize={"small"} color="light" opacity={0.5}/></Tooltip>}
                    </ListItemButton>
                  </List>
                </SubMenu>
            ))}

            <MenuDivider />
          </>}

          { isAuthorised && <>
          <ListItem key={"linked-accounts"} disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick} component={RouterLink} to={routes.brokerAccountList}>
              <ListItemIcon sx={ListItemIconStyles(open)} >
                <img src={BrokerIcon}/>
              </ListItemIcon>
              <ListItemText primary={"Broker Accounts"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <MenuDivider />
          </>}

          { isAuthorised && <>
          <ListItem key={"auto-trade"} disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={ListItemButtonStyles(open)} onClick={handleLinkClick} component={RouterLink} to={routes.autoTrade}  >
              <ListItemIcon sx={ListItemIconStyles(open)} >
                <img src={AutotraderIcon}/>
              </ListItemIcon>
              <ListItemText primary={"AutoTrade"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          </>}

          </Box>

          <Box sx={{ flexGrow: 1}} >&nbsp;</Box>

          { isAuthorised && <>
          <Box>
            <MenuDivider />
            <ListItem key={"logout"} disablePadding sx={{ display: 'block' }}>
              <ListItemButton sx={ListItemButtonStyles(open)} onClick={() => {logout({redirectTo: routes.leaderBoard}) ; handleLinkClick()}} >
                <ListItemIcon sx={ListItemIconStyles(open)} >
                  <img src={LogoutIcon}/>
                </ListItemIcon>
                <ListItemText primary={"Log Out"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Box>
          </>}

        </Box>
      </List>
  )
}