import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunk
export const fetchTrades = createAsyncThunk(
  'trade/fetchTrades', 
  async ({strategyId}) => {
    return authAxios.get(`/trade/strategy/${strategyId}/`).then(response => response.data);
  }
);

export const tradesAdapter = createEntityAdapter();

// Slice
export const tradeSlice = createSlice({
  name: 'trade',
  initialState: tradesAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrades.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        tradesAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Selectors
export const {
  selectById: selectTradeById,
  selectAll: selectAllTrades,
} = tradesAdapter.getSelectors((state) => state.trade);

// Custom selector to select active trades
export const selectActiveTrades = (state) => {
  return selectAllTrades(state).filter(trade => trade.status === 'ACTIVE');
};

// Custom selector to select closed trades
export const selectClosedTrades = (state) => {
  return selectAllTrades(state).filter(trade => trade.status === 'CLOSED');
};

export default tradeSlice.reducer;