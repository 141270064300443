import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Collapse, IconButton, Card, CardContent, Stack, Typography, Menu, MenuItem, Box
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { 
  KeyboardArrowDown, 
  KeyboardArrowUp, 
  SubdirectoryArrowRight, 
  MoreVert 
} from '@mui/icons-material';
import { formatDateTime } from 'utils/dateTime';
import { useWebTrader } from 'utils/WebTraderProvider';
import { useDispatch } from 'react-redux';
import { fetchOrders } from 'redux/order/orderSlice';
import { enqueueSnackbar } from 'notistack';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& > table > tbody > tr > td, & > table > tbody > tr > th': {
    borderBottom: 'unset',
    textAlign: ''
  },
  '& > table > thead > tr > th': {
    textAlign: ''
  }
}));

const StatusDot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  display: 'inline-block',
  marginRight: theme.spacing(1)
}));

const OrderRow = ({ order, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isChild = Boolean(order.parent_order_id);
  const { startOrderModification } = useWebTrader();
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    onDelete(order.id);
    const message = (
      <Box>
        <Typography variant="subtitle2">Order Cancelled</Typography>
        <Typography variant="body2">
          {order.data.side} {order.data.quantity} {order.data.symbol}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Signal ID: {order.id}
        </Typography>
      </Box>
    );
    enqueueSnackbar(message, { 
      variant: 'success'
    });
    handleMenuClose();
  };

  const handleModify = () => {
    console.log(order);
    startOrderModification(order);
    handleMenuClose();
  };

  return (
    <>
      <TableRow 
        sx={{ 
          '& > *': { borderBottom: 'unset' },
          bgcolor: isChild ? 'action.hover' : 'background.paper',
          borderTop: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{order.id}</TableCell>
        <TableCell>{formatDateTime(order.created)}</TableCell>
        <TableCell>
          {isChild && <SubdirectoryArrowRight fontSize="small" />} {order.data.symbol}
        </TableCell>
        <TableCell>{order.data.order_type}</TableCell>
        <TableCell>{order.data.side}</TableCell>
        <TableCell>{order.data.quantity}</TableCell>
        <TableCell>{order.data.average_price || order.data.limit_price || order.data.stop_price || order.data.price||'-'}</TableCell>
        <TableCell>
          {order.active && order.order_status[0]?.order_status === 'PendingCancel' ? (
            <><StatusDot sx={{ backgroundColor: 'warning.main' }}/>Canceling </>
          ) : order.active ? (
            <><StatusDot />Working </>
          ) : order.order_status[0]?.order_status}
        </TableCell>
        <TableCell padding="none" sx={{ width: '48px' }}>
          <IconButton
            size="small"
            onClick={handleMenuOpen}
            disabled={order.active === false }
          >
            <MoreVert fontSize="small" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem 
              onClick={handleModify}
              disabled={!order.parent_order_id && order.order_status[0]?.cannot_cancel_order}
            >
              {order.parent_order_id ? 'Modify Order' : 'Replace Order'}
            </MenuItem>
            <MenuItem 
              onClick={handleCancel}
              disabled={order.order_status[0]?.cannot_cancel_order}
            >
              Cancel Order
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card variant="outlined" sx={{ m: 1 }}>
              <CardContent sx={{ py: 3 }}>
                <Stack 
                  direction="row" 
                  spacing={4} 
                  flexWrap="wrap" 
                  alignItems="center"
                  useFlexGap 
                  sx={{ '& > *': { flex: '0 0 auto', minWidth: 'auto' }}}
                >
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Exchange:</strong>&nbsp;{order.coin_info?.exchange || '-'}
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Description:</strong>&nbsp;{order.coin_info?.company_name || '-'}
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Currency:</strong>&nbsp;{order.coin_info?.currency || '-'}
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Time in Force:</strong>&nbsp;{order.data?.tif?.toUpperCase() || '-'}
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Filled:</strong>&nbsp;{order.order_status?.[0]?.size_and_fills || `0/${order.data?.quantity || 0}`}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const OrdersTable = ({ orders = [], onDeleteOrder, strategyId, isActive }) => {
  const dispatch = useDispatch();

  // Set up polling only when tab is active
  useEffect(() => {
    if (!isActive) return;

    // Initial fetch with refresh
    dispatch(fetchOrders({ strategyId, refresh: true }));

    // Set up polling interval
    const intervalId = setInterval(() => {
      dispatch(fetchOrders({ strategyId, refresh: true }));
    }, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch, strategyId, isActive]);

  const getOrdersHierarchy = (orders) => {
    const parentOrders = orders.filter(order => !order.parent_order_id);
    
    return parentOrders.reduce((acc, parentOrder) => {
      acc.push(parentOrder);
      
      const childOrders = orders.filter(order => 
        order.parent_order_id === parentOrder.id
      );
      acc.push(...childOrders);
      
      return acc;
    }, []);
  };

  return (
    <StyledTableContainer component={Paper} sx={{ mt: 1 }}>
      <Table size="small" aria-label="orders table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>Signal ID</TableCell>
            <TableCell sx={{ minWidth: 140 }}>Date/Time</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Side</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Price/Fill</TableCell>
            <TableCell>Status</TableCell>
            <TableCell padding="none" sx={{ width: '48px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length > 0 ? (
            getOrdersHierarchy(orders).map(order => (
              <OrderRow 
                key={order.id}
                order={order}
                onDelete={(orderId) => onDeleteOrder(orderId)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center">
                No records found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default OrdersTable;
