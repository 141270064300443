import React, { useMemo } from 'react';
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContainer from "@mui/material/TableContainer";
import Grid from '@mui/material/Unstable_Grid2';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const generateChartData = () => {
  const data = [];
  let value = 50.0;
  let lastValue = value;
  const startDate = Date.UTC(2024, 0, 1);
  const endDate = Date.UTC(2024, 11, 31);
  const dayMs = 24 * 3600 * 1000;

  // Helper function to add random time to a UTC date
  const addRandomTime = (date) => {
    const hours = Math.floor(Math.random() * 24);
    const minutes = Math.floor(Math.random() * 60);
    const seconds = Math.floor(Math.random() * 60);
    return date + (hours * 3600 + minutes * 60 + seconds) * 1000;
  };

  // Add initial datapoint with random time
  data.push([addRandomTime(startDate), Number(value.toFixed(2))]);

  for (let date = startDate + dayMs; date <= endDate; date += dayMs) {
    // 45% chance of no change, 55% chance of change
    if (Math.random() > 0.45) {
      const change = (Math.random() - 0.45) * 0.5;
      value = value * (1 + change / 100);
      value += 0.01;

      // Only add datapoint if value changed significantly (more than 0.01)
      if (Math.abs(value - lastValue) > 0.01) {
        data.push([addRandomTime(date), Number(value.toFixed(2))]);
        lastValue = value;
      }
    }
  }

  // Always add the last point if it's different from the last recorded point
  const lastPoint = data[data.length - 1];
  if (lastPoint[0] !== endDate && Math.abs(value - lastValue) > 0.01) {
    data.push([addRandomTime(endDate), Number(value.toFixed(2))]);
  }

  return data;
};

const HypotheticalPerformance = ({ strategyName }) => {
  // Memoize the chart data
  const chartData = useMemo(() => generateChartData(), []);

  const chartOptions = {
    accessibility: {
      enabled: false
    },
    chart: {
      zoomType: 'x',
      backgroundColor: '#F2F2F2',
      style: {
        fontSize: '14px'
      }
    },
    title: {
      text: `${strategyName} with commission and fees`,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    subtitle: {
      text: document.ontouchstart === undefined ?
        'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
      align: 'left',
      style: {
        fontSize: '14px'
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: "{value:%b'%y}",
        style: {
          fontSize: '14px'
        }
      },
      ordinal: false
    },
    yAxis: {
      title: {
        text: '',
        style: {
          fontSize: '14px'
        }
      },
      labels: {
        formatter: function() {
          return '$' + this.value + 'k';
        },
        style: {
          fontSize: '14px'
        }
      },
      gridLineWidth: 0,
      tickInterval: 2.5
    },
    colors: ["#16171a"],
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 2
          },
          stops: [
            [0, "#E6E6E6"],
          ]
        },
        marker: {
          radius: 2,
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true
            }
          }
        },
        lineWidth: 1.5,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null,
        connectNulls: true,
        step: false
      }
    },
    tooltip: {
      headerFormat: '',
      pointFormat: `${strategyName} with commission and fees:<b> ${'$'}{point.y:,.2f}k</b><br/><span style="font-size: 11px; color: #666666">{point.x:%A, %B %e, %H:%M:%S}</span>`,
      useHTML: true,
      style: {
        fontSize: '14px'
      }
    },
    series: [{
      type: 'area',
      name: 'Account Value',
      data: chartData,
      gapSize: 1,
      gapUnit: 'day'
    }]
  };

  return (
    <Grid md={8} xs={12} order={{ xs: 2, md: 1 }}>
      <Typography variant="h6">Monthly Returns</Typography>
      <Typography color={"secondary.dark"} fontWeight={500}>Hypothetical</Typography>
      <TableContainer>
        <Table size="small">
          <caption>Includes system fee and Interactive Brokers commissions and fees</caption>
          <TableHead>
            <TableRow>
              <TableCell />
              {months.map((month) => (
                <TableCell key={month} align="center">
                  {month}
                </TableCell>
              ))}
              <TableCell align="center">
                <Typography fontWeight="bold">YTD</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <Typography variant="subtitle1">2025</Typography>
              </TableCell>
              {months.map((month) => (
                <TableCell key={`2025-${month}`} align="center">
                  N/A
                </TableCell>
              ))}
              <TableCell align="center">
                N/A
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mt: 3 }}>Performance</Typography>
      <Typography color={"secondary.dark"} fontWeight={500}>Hypothetical</Typography>
      <Box sx={{ mt: 3}}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </Box>
    </Grid>
  );
};

export default HypotheticalPerformance;
