import * as Sentry from "@sentry/react";

const initSentry = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Environment configuration
    environment: process.env.REACT_APP_HOST_ENV,
    
    // Performance Monitoring
    tracesSampleRate: process.env.REACT_APP_HOST_ENV === 'production' ? 0.2 : 1.0,
    tracePropagationTargets: [
      "backend.tppglobal.io",
      "backend.staging.tppglobal.io",
    ],
    
    // Session Replay
    replaysSessionSampleRate: process.env.REACT_APP_HOST_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0
  });
};

export default initSentry; 