import React, { useEffect, useRef } from "react";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import {Card, Grid, Typography, Button, ButtonGroup} from '@mui/material';
import Box from "@mui/material/Box";
import PositionsOrders from "components/organisms/WebTrader/PositionsOrders";
import TradeTabs from "components/organisms/WebTrader/TradeTabs";
import {useParams, useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectStrategyById } from "redux/strategy/strategySlice";
import TradingViewChart from "components/organisms/WebTrader/TradingViewChart";
import { useDispatch } from 'react-redux';
import {useAuth0} from "@auth0/auth0-react";
import { WebTraderProvider } from "utils/WebTraderProvider";
import webSocketManager from "../../utils/WebSocketManager";
import { routes } from "routes";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function StrategyWebTrader() {
  const navigate = useNavigate();
  const params = useParams();
  const strategyId = params.strategyId;
  const strategy = useSelector((state) => selectStrategyById(state, strategyId));
  
  return (
    <>
      <Box sx={{ mb: 5, display: {xs: 'none', sm: 'block'} }}>
        <ButtonGroup orientation="horizontal" variant="outlined">
          <Button 
            variant="outlined" 
            onClick={() => navigate(routes.strategyDetails.replace(":strategyId", strategyId))}
            startIcon={<ArrowBackIcon />}
          >
            Back to Strategy
          </Button>
        </ButtonGroup>
      </Box>

      <PageHeader text1={"Web"} text2={"Trader"} description={strategy.name}/>

      <WebTraderProvider >
      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={12} sm={8}>
          <Card sx={{p: 3, mb: 2}}>
            <TradingViewChart />
          </Card>
          <Card sx={{p: 3}}>
            <PositionsOrders strategyId={strategyId} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{p: 3, pt:1}}>
            <Box>
              <TradeTabs strategyId={strategyId} />
            </Box>
            <Box sx={{ pt: 4 }}>
            </Box>
          </Card>
        </Grid>
        </Grid>
      </WebTraderProvider>
    </>
  )
}