import React, {useEffect, useState, useMemo} from "react";
import _ from "lodash"
import {useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';
import {useSelector, useDispatch} from 'react-redux';
import {styled, useTheme} from '@mui/material/styles';
import {routes} from "routes";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {Box} from "@mui/system";
import { PageHeader } from "components/molecules/PageHeader/PageHeader";
import {Paper as CustomPaper} from "components/atoms/Paper/Paper";
import {Card, MenuList, Switch} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from "@mui/material/Stack";
import { Table, TableBody, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/atoms/TabPanel/TabPanel";
import { DataGrid } from '@mui/x-data-grid';
import CallMissedOutgoingOutlinedIcon from '@mui/icons-material/CallMissedOutgoingOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {selectStrategyById, togglePublicState} from "redux/strategy/strategySlice";
import {fetchTrades, selectAllTrades, selectClosedTrades, selectActiveTrades} from "redux/trade/tradeSlice";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {createSubscription, selectSubscriptionByStrategyId} from "redux/subscription/subscriptionSlice";
import StyledCardComponent from "components/molecules/StyledCardElement/StyledCardComponent";
import LinearProgress from '@mui/material/LinearProgress';
import {selectLinkedAccountByBrokerID} from "redux/linkedAccount/linkedAccountSlice";
import {deleteSimulation, selectSimulationByStrategyId} from "redux/simulation/simulationSlice";
import {useConfirmation} from "utils/ConfirmationProvider";
import { ResultsDisclaimer } from "components/molecules/ResultsDisclaimer/ResultsDisclaimer";
import HypotheticalPerformance from "components/organisms/Strategy/HypotheticalPerformance";
import { fetchOrders } from "redux/order/orderSlice";

const LargeWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

// Styles for P/L values
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .positive-value': {
    color: theme.palette.success.dark
  },
  '& .negative-value': {
    color: theme.palette.error.main
  },
  '& .MuiDataGrid-main': {
    backgroundColor: 'white'
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'white'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.grey[100]
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: 'transparent'
  }
}));

/**
 * StrategyDetails Component
 * Main component for displaying detailed information about a trading strategy
 * Includes performance metrics, trade history, and subscription management
 */
export default function StrategyDetails() {
  // Theme and navigation hooks
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirmation()
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const strategyId = params.strategyId;
  const strategy = useSelector((state) => selectStrategyById(state, strategyId));
  const subscription = useSelector((state) => selectSubscriptionByStrategyId(state, strategyId));
  const simulation = useSelector((state) => selectSimulationByStrategyId(state, strategyId));
  const linked_account = useSelector((state) => selectLinkedAccountByBrokerID(state, strategy.broker.type));
  const stripe = useStripe();
  const elements = useElements();
  const trades = useSelector(selectAllTrades);
  const closedTrades = useSelector(selectClosedTrades);
  const activeTrades = useSelector(selectActiveTrades);
  
  // Local state management
  const [value, setValue] = React.useState(0);  // Current tab index
  const [anchorEl, setAnchorEl] = React.useState(null);  // Menu anchor element
  const [openIndex, setOpenIndex] = React.useState(null);  // Active menu index
  const [subscribeSubmitting, setSubscribeSubmitting] = useState(false);  // Subscription form state
  const [subscribeError, setSubscribeError] = useState(null);  // Subscription error state
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);  // Subscription dialog visibility
  
  // Event handlers for subscription management
  const handleOpenSubscriptionDialog = () => {
    if (_.isEmpty(linked_account)) {
      confirm({
        title: `Connect a Broker Account`,
        message: <>In order to subscribe to <b>{strategy.name}</b> you need to connect to your <b>{strategy.broker.name}</b> account.</>,
        onConfirm: async () => {
          navigate(`${routes.brokerAccountConnect}?broker=${strategy.broker.type}`)
        },
        maxWidth: "md"
      });
    } else {
      if(linked_account[0].status === 'pending') {
        confirm({
          title: `Broker Account is awaiting approval`,
          message: <>You <b>{strategy.broker.name}</b> account is awaiting approval. <br></br> Please wait for the account to be activated. </>,
          onConfirm: async () => {},
          maxWidth: "md"
        });
      } else {
        setSubscribeError(false)
        setOpenSubscriptionDialog(true);
      }
    }
  }

  const handleCloseSubscriptionDialog = () => {
    setOpenSubscriptionDialog(false);
  };

  const handleSubmitSubscription = async (event) => {
    setSubscribeSubmitting(true)
    setSubscribeError(false)
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setSubscribeError(result.error.message)
      setSubscribeSubmitting(false)
      return;
    }
    const token = result.token;
    const subscriptionData = {
      token: token.id,
      strategy: strategy.id,
      linked_account: linked_account[0].id
    };
    if(!_.isEmpty(simulation)) {
      await dispatch(deleteSimulation(simulation[0].id));
    }
    await dispatch(createSubscription(subscriptionData)).unwrap();
    setSubscribeSubmitting(false)
    setOpenSubscriptionDialog(false)
    enqueueSnackbar('You have subscribed successfully.', { variant: 'success' });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setOpenIndex(parseInt(event.currentTarget.getAttribute('data-index')))
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenIndex(null);
  };

  const togglePublic = async () => {
    await dispatch(togglePublicState(strategyId)).unwrap();
    enqueueSnackbar(`Strategy has been set to "${strategy.public? 'Private': 'Public'}".`, { variant: 'success' });
  }

  // Column definitions for trade tables
  const open_position_columns = [
    { field: 'created', headerName: 'Date', flex: 1, minWidth: 200, valueFormatter: (params) => {
      return new Date(params.value).toLocaleString();
    }},
    { field: 'symbol', headerName: 'Symbol', flex: 0.7, minWidth: 120 },
    { field: 'trade_type', headerName: 'Side', flex: 0.7, minWidth: 100, valueFormatter: (params) => {
      return params.value?.charAt(0).toUpperCase() + params.value?.slice(1) || 'N/A';
    }},
    { field: 'quantity', headerName: 'Quant', flex: 0.7, minWidth: 100, valueFormatter: (params) => {
      return params.value?.toLocaleString() || 'N/A';
    }},
    { field: 'average_entry_price', headerName: 'Basis', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      return params.value ? `$${params.value.toLocaleString()}` : 'N/A';
    }},
    { field: 'last_price', headerName: 'Price', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      return params.value ? `$${params.value.toLocaleString()}` : 'N/A';
    }},
    { field: 'unrealized_pnl', headerName: 'Unrealized P/L', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      if (params.value === null || params.value === undefined) return 'N/A';
      const value = typeof params.value === 'string' ? parseFloat(params.value) : Number(params.value);
      if (isNaN(value)) return 'N/A';
      
      if (value >= 0) {
        return `$${value.toFixed(2)}`;
      } else {
        return `($${Math.abs(value).toFixed(2)})`;
      }
    }, cellClassName: (params) => {
      if (params.value === null || params.value === undefined) return '';
      const value = typeof params.value === 'string' ? parseFloat(params.value) : Number(params.value);
      return value >= 0 ? 'positive-value' : 'negative-value';
    }}
  ];

  const trade_columns = [
    { field: 'created', headerName: 'Opened Date/Time', flex: 1, minWidth: 200, valueFormatter: (params) => {
      return new Date(params.value).toLocaleString();
    }},
    { field: 'symbol', headerName: 'Symbol', flex: 0.7, minWidth: 120 },
    { field: 'trade_type', headerName: 'Side', flex: 0.7, minWidth: 100, valueFormatter: (params) => {
      return params.value?.charAt(0).toUpperCase() + params.value?.slice(1) || 'N/A';
    }},
    { field: 'max_quantity', headerName: 'Quant', flex: 0.7, minWidth: 100, valueFormatter: (params) => {
      return params.value?.toLocaleString() || 'N/A';
    }},
    { field: 'average_entry_price', headerName: 'Avg Price', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      return params.value ? `$${params.value.toLocaleString()}` : 'N/A';
    }},
    { field: 'closed_at', headerName: 'Closed Date/Time', flex: 1, minWidth: 200, valueFormatter: (params) => {
      return params.value ? new Date(params.value).toLocaleString() : 'N/A';
    }},
    { field: 'closing_price', headerName: 'Avg Price', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      return params.value ? `$${params.value.toLocaleString()}` : 'N/A';
    }},
    { field: 'drawdown', headerName: 'Drawdown', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      return params.value ? `${params.value}%` : 'N/A';
    }},
    { field: 'realized_pnl', headerName: 'P/L', flex: 0.7, minWidth: 120, valueFormatter: (params) => {
      if (params.value === null || params.value === undefined) return 'N/A';
      const value = typeof params.value === 'string' ? parseFloat(params.value) : Number(params.value);
      if (isNaN(value)) return 'N/A';
      
      if (value >= 0) {
        return `$${value.toFixed(2)}`;
      } else {
        return `($${Math.abs(value).toFixed(2)})`;
      }
    }, cellClassName: (params) => {
      if (params.value === null || params.value === undefined) return '';
      const value = typeof params.value === 'string' ? parseFloat(params.value) : Number(params.value);
      return value >= 0 ? 'positive-value' : 'negative-value';
    }}
  ];

  // Calculate summary statistics using useMemo
  const summaryStats = useMemo(() => {
    if (!strategy) return {
      startDate: null,
      totalTrades: 0,
      profitableTrades: 0,
      profitablePercent: 0,
      leverage: {
        average: 0,
        maximum: 0
      }
    };

    return {
      startDate: new Date(strategy.created),
      totalTrades: closedTrades.length,
      profitableTrades: closedTrades.filter(t => t.realized_pnl > 0).length,
      profitablePercent: closedTrades.length > 0 ? (closedTrades.filter(t => t.realized_pnl > 0).length / closedTrades.length) * 100 : 0,
      leverage: {
        average: 0,
        maximum: 0
      }
    };
  }, [trades, closedTrades, strategy]);

  useEffect(() => {
    dispatch(fetchTrades({ strategyId, refresh: true }));
    
    const intervalId = setInterval(() => {
      dispatch(fetchTrades({ strategyId }));
    }, 10000); // Poll every 10 seconds

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [dispatch, strategyId, strategy?.owned]);

  return (
    <>
      {strategy && <>
        {/* Strategy Management Menu - Only visible to strategy owners */}
        {strategy.owned && <>
               <Menu anchorEl={anchorEl} open={openIndex === 0 } onClose={handleClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}   >
                <MenuList sx={{ pt:0, pb: 0}}>
                  <MenuItem onClick={() => navigate(routes.strategyWebTrader.replace(":strategyId", strategy.id))}  >Enter new trade</MenuItem>
                  <MenuItem onClick={() => navigate(routes.strategyFormEdit.replace(":strategyId", strategy.id))}>Edit your strategy</MenuItem>
                  {/* <NestedMenuItem sx={{pl: 1}}
                    label="Market your strategy"
                    parentMenuOpen={true}
                  >
                      <MenuItem onClick={handleClose}>Invite to strategy</MenuItem>
                      <MenuItem onClick={handleClose}>Feature this strategy </MenuItem>
                  </NestedMenuItem> */}
                  {/* <MenuItem onClick={handleClose}>Extend listing period</MenuItem> */}
                  <MenuItem onClick={handleClose}>Rescale</MenuItem>
                  <MenuItem onClick={handleClose}>Position size limit</MenuItem>
                </MenuList>
              </Menu>
                <Menu anchorEl={anchorEl} open={openIndex === 1} onClose={handleClose}   anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} >
                  <MenuList sx={{ pt:0, pb: 0}}>
                    <MenuItem onClick={handleClose}>List subscribers</MenuItem>
                  </MenuList>
                </Menu>


              <Box  sx={{ mb: 5, display: {xs: 'none', sm: 'block'}}} >
          <ButtonGroup orientation="horizontal" variant="outlined" >
            <Button variant={"outlined"} onClick={() => navigate(routes.strategyWebTrader.replace(":strategyId", strategy.id))} startIcon={<CallMissedOutgoingOutlinedIcon />} >Enter Trade</Button>
            <Button onClick={handleClick} data-index={0} variant="outlined" startIcon={<SettingsOutlinedIcon/>}endIcon={<KeyboardArrowDownIcon/>} size={mobile? "large": "small"}> Manage </Button>
            <Button onClick={handleClick} data-index={1} variant="outlined" startIcon={<GroupAddOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} size={mobile? "large": "small"}> Subscribers </Button>
          </ButtonGroup>

        </Box>


          </>}

      <Box sx={{ mb: 5, display: { xs: "block", sm: "flex"}}} justifyContent="space-between" alignItems="center">
        <PageHeader text1={strategy.name} text2={"strategy"} description={"These are hypothetical performance results that have certain inherent limitations."}/>
        {strategy.owned ?
         <Card sx={{p: 1, pl: 2, pr: 2, mt: { xs: 5, sm: 0}}}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Private</Typography>
            <Switch color="secondary" checked={strategy.public} onClick={() => togglePublic()}/>
            <Typography>Public</Typography>
          </Stack>
        </Card> :
          <>
          {_.isEmpty(subscription) &&
            <>

        <Button variant="contained" color="secondary" sx={{ mt: 3}} onClick={handleOpenSubscriptionDialog} disabled={!isAuthorised}>
          <Typography sx={{ textTransform: 'none' }}>
           <b>Subscribe</b> <br />
           <small>Full access for ${strategy.charge}/mo</small>
          </Typography>
        </Button>

           <Dialog
             fullWidth={true}
               open={openSubscriptionDialog}
               onClose={handleCloseSubscriptionDialog}
           >
               <DialogTitle >
                 Subscribe
               </DialogTitle>
               <DialogContent>

                  <StyledCardComponent onCardChange={() => setSubscribeError(null)} />
                 {setSubscribeError && <div style={{ color: 'red' }}>{subscribeError}</div>}

                  <Grid container>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={6} sx={{mt: 3}}>
                      <Table size="small" align="right"  sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }}>
                        <TableBody>
                          <TableRow align="right">
                            <TableCell align="right">Strategy Name</TableCell>
                            <TableCell align="right"><Typography fontWeight={500} variant="span">{strategy.name}</Typography></TableCell>
                          </TableRow>
                            <TableRow>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right"><Typography fontWeight={500} variant="span">{strategy.charge} $</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Recurrence</TableCell>
                                <TableCell align="right"><Typography fontWeight={500} variant="span">Monthly</Typography></TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
               </DialogContent>
               <DialogActions sx={{p: 3}}>
                   <Button onClick={handleCloseSubscriptionDialog} color="primary">
                       Close
                   </Button>
                 <Button onClick={handleSubmitSubscription} color="secondary" variant="contained" disabled={subscribeSubmitting}>
                    Subscribe
                </Button>
               </DialogActions>
             {subscribeSubmitting && <Box sx={{ width: '100%' }}> <LinearProgress color="secondary"/> </Box>}
            </Dialog> 
          </>
          }
          </>
        }

      </Box>

       <Box  sx={{ mb: 5, display: {xs: 'block', sm: 'none'}}} >

            <ButtonGroup orientation="vertical" variant="outlined" fullWidth>
            <Button variant={"outlined"} startIcon={<CallMissedOutgoingOutlinedIcon />} size="large">Enter Trade</Button>
            <Button variant={"outlined"} startIcon={<VisibilityOffOutlinedIcon/>} size="large">Unsubscribe</Button>
            <Button variant={"outlined"} startIcon={<AutorenewOutlinedIcon/>} size="large">Auto Trade</Button>
            <Button onClick={handleClick} data-index={0} variant="outlined" size="large" startIcon={<SettingsOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} > Manage </Button>
            <Button onClick={handleClick} data-index={1} variant="outlined" size="large" startIcon={<GroupAddOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} > Subscribers </Button>
          </ButtonGroup>
       </Box>

    {strategy.owned === false &&
      <Box sx={{ flexGrow: 1, mb: 5 }}>
      <Grid container spacing={2}>
          <Grid xs={6} sm={4} md={2}>
            <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center" >N/A</Typography>
             <LargeWidthTooltip
              title={
               <>
                 <p>Rate of Return Calculations</p>
                 <p>Overview</p>
                 <p>To comply with NFA regulations, we display Cumulative Rate of Return for strategies with a track record of less than one year. For strategies with longer track records, we display Annualized (Compounded) Rate of Return. </p>
                 <p>How Annualized (Compounded) Rate of Return is calculated</p>
                 <p>= ((Ending_equity / Starting_equity) ^ (1 / age_in_years)) - 1</p>
                 <p>Remember that, following NFA requirements, strategy subscription costs and estimated commissions are included in marked-to-market equity calculations.</p>
                 <p>All results are hypothetical.</p>
               </>
               }>
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Annual Return (Compounded)&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
             </LargeWidthTooltip>
            </Card>
          </Grid>
        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="error.main" align="center">N/A</Typography>
            <LargeWidthTooltip
              title={
                <>
                  <p>The largest peak-to-valley historical drawdown of the Model Account was 46.6% and occurred between 2021-09-13 and 2022-10-02. In terms of dollars, the largest peak-to-valley drawdown of the Model Account was $41,562. Future drawdowns may be greater than this number.</p>
                  <p>This number assumes typical broker commissions and fees are included in strategy performance.</p>
                </>
              }>
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Max Drawdown&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
            </LargeWidthTooltip>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="light.main" align="center">{summaryStats.totalTrades || '0'}</Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Num Trades</Typography>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="light.main" align="center">
              {summaryStats.profitablePercent ? `${Math.round(summaryStats.profitablePercent)}%` : '0'}
            </Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">% Profitable</Typography>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center">N/A</Typography>
            <Tooltip title="The Profit Factor (also called the Win-to-Loss Ratio) is calculated by dividing total trade profits by total trade losses.">
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Profit Factor&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
            </Tooltip>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center">N/A</Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Win Months</Typography>
          </Card>
        </Grid>

        </Grid>
      </Box>
    }

      <Box >
        <Grid container spacing={2}>
          <HypotheticalPerformance strategyName={strategy.name} />
          <Grid md={4} xs={12} order={{ xs: 1, md: 2 }} sx={{ mb: 3}}>
            <CustomPaper variant="light2" sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 3}}>Summary Statistics</Typography>

              <Table size="small">
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head">Strategy began</TableCell>
                    <TableCell align="right">
                      {summaryStats.startDate ? summaryStats.startDate.toLocaleDateString() : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="This is our estimate of the minimum amount of capital to follow a strategy, assuming you use the smallest reasonable AutoTrade Scaling % for the strategy.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Suggested Minimum Capital&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      ${strategy?.min_capital?.toLocaleString() || 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head"># Trades</TableCell>
                    <TableCell align="right">{summaryStats.totalTrades}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head"># Profitable</TableCell>
                    <TableCell align="right">{summaryStats.profitableTrades}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">% Profitable</TableCell>
                    <TableCell align="right">
                      {summaryStats.profitablePercent ? `${Math.round(summaryStats.profitablePercent)}%` : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="Correlation with S&P500 shows how closely the strategy's returns follow the broader market. A lower correlation suggests the strategy may provide better diversification benefits.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Correlation S&P500&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">N/A</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="Sharpe Ratio measures risk-adjusted returns. A higher ratio indicates better returns relative to the risk taken.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Sharpe Ratio&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">N/A</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="Sortino Ratio is similar to Sharpe Ratio but only penalizes downside volatility. A higher ratio indicates better risk-adjusted returns considering only harmful volatility.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Sortino Ratio&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">N/A</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="Beta measures the strategy's volatility compared to the market (S&P500). A beta of 1 means it moves with the market, less than 1 means lower volatility than the market.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Beta&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">N/A</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">
                      <Tooltip title="Alpha represents the strategy's excess return compared to the market benchmark. A positive alpha indicates outperformance.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Alpha&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">N/A</TableCell>
                  </TableRow>
                  <TableRow hover sx={{ "& td": { border: 0 } }}>
                    <TableCell variant="head">
                      <Tooltip title="Leverage shows how much borrowed capital is used relative to the strategy's capital. Higher leverage means higher potential returns but also higher risk.">
                        <Typography variant="" alignItems="center" component={Stack} direction="row">
                          Leverage&nbsp;<InfoOutlinedIcon fontSize="string"/>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      Average:&nbsp; N/A<br/>
                      Maximum:&nbsp; N/A
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

            </CustomPaper>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} sm={12} sx={{ mt: 10, mb: 5}}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
                backgroundColor: 'transparent',
                '& .MuiTab-root': {
                  minHeight: '40px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: 'text.primary',
                  backgroundColor: 'transparent',
                  margin: '0 4px',
                  borderRadius: '6px 6px 0 0',
                  transition: 'all 0.2s ease-in-out',
                  border: '1px solid transparent',
                  position: 'relative',
                  '&.Mui-selected': {
                    color: 'white',
                    backgroundColor: 'secondary.main',
                    borderColor: 'secondary.main',
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    color: 'white',
                    borderColor: 'secondary.dark',
                  },
                  '&:first-of-type': {
                    marginLeft: 0,
                  },
                  '&:last-of-type': {
                    marginRight: 0,
                  },
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'secondary.dark',
                  height: 3,
                },
              }}
            >
              <Tab label="Trade Record" />
              <Tab label="Statistics" />
              <Tab label="Description" />
            </Tabs>

            <Box sx={{m: 1, mt: 3}}>
              <TabPanel name="trade-record" value={value} index={0}>
                {strategy.owned && (
                  <>
                    <Typography 
                      variant="h6" 
                      component="div" 
                      sx={{ 
                        color: theme.palette.primary.light,
                        mb: 2
                      }}
                    >
                      Open Positions
                    </Typography>
                    {activeTrades.length > 0 ? (
                      <StyledDataGrid
                        rows={activeTrades}
                        columns={open_position_columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                          sorting: {
                            sortModel: [{ field: 'created', sort: 'desc' }],
                          },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        getRowId={(row) => row.id}
                        autoHeight
                        sx={{ mb: 4 }}
                      />
                    ) : (
                      <Box sx={{ mb: 5 }}>
                        <Typography>No open positions.</Typography>
                      </Box>
                    )}
                  </>
                )}

                <Typography variant="h6" gutterBottom>Trading Record</Typography>
                <StyledDataGrid
                  rows={closedTrades}
                  columns={trade_columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    sorting: {
                      sortModel: [{ field: 'created', sort: 'desc' }],
                    },
                  }}
                  pageSizeOptions={[10, 25, 50]}
                  getRowId={(row) => row.id}
                  autoHeight
                />
              </TabPanel>
              <TabPanel name="statistics" value={value} index={1}>
                <Grid xs={12} sm={8}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2}>
                        <Typography variant="h5">Statistics</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>Strategy began</TableCell>
                      <TableCell align="left">
                        {summaryStats.startDate ? summaryStats.startDate.toLocaleDateString() : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Suggested Minimum Capital</TableCell>
                      <TableCell align="left">
                        ${strategy?.min_capital?.toLocaleString() || 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell># Trades</TableCell>
                      <TableCell align="left">{summaryStats.totalTrades}</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell># Profitable</TableCell>
                      <TableCell align="left">{summaryStats.profitableTrades}</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>% Profitable</TableCell>
                      <TableCell align="left">
                        {summaryStats.profitablePercent ? `${Math.round(summaryStats.profitablePercent)}%` : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Correlation S&P500</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Sharpe Ratio</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Sortino Ratio</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Beta</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Alpha</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                  </TableBody>

                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2} sx={{ pt: 3 }}>
                        <Typography variant="h5">Model Account Values (Raw)</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>Sortino Ratio</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Beta</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Alpha</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                  </TableBody>

                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2} sx={{ pt: 3 }}>
                        <Typography variant="h5">Ratios</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>Sortino Ratio</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Beta</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Alpha</TableCell>
                      <TableCell align="left">N/A</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </Grid>
              </TabPanel>
              <TabPanel name="account" value={value} index={2}>
                <Typography variant="h6" gutterBottom>Strategy Description</Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                  {strategy.description || 'No description available for this strategy.'}
                </Typography>
              </TabPanel>
              </Box>
            </Grid>
          </Grid>

        </Box>

       <Box sx={{mt: 5}}>
       <ResultsDisclaimer/>
      </Box>

        </>}
    </>
  )
}
