export const routes = {
    home: '/',
    account: '/account',
    userProfile: '/profile',
    userInitialProfile: '/initial-profile',
    dashboard: '/dashboard',
    strategyDetailsList: '/strategy/list/:strategyId/',
    strategyDetails: '/strategy/:strategyId/',
    strategyFormCreate: '/strategy/create/',
    strategyFormEdit: '/strategy/:strategyId/edit',
    strategyWebTrader: '/strategy/:strategyId/web-trader',
    leaderBoard: '/leader-board',
    watchList: '/watch-list',
    autoTrade: '/auto-trade',
    autoTradeFormCreate: '/auto-trade/:strategyId/',
    autoTradeFormEdit: '/auto-trade/:strategyId/edit',
    brokerAccountList: '/broker-accounts',
    brokerAccountConnect: '/broker-accounts/connect',
    KYCAnonymous: '/kyc',
}
