import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, updateOrder, fetchOrders } from 'redux/order/orderSlice';
import {
  Box,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
  Chip,
  CardContent,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Autocomplete, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import MuiTextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import authAxios from 'utils/authAxios';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from 'notistack';

import webSocketManager from 'utils/WebSocketManager';
import { useWebTrader } from 'utils/WebTraderProvider';
import { fetchTrades } from 'redux/trade/tradeSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { routes } from 'routes';




const TradePanel = ({ strategyId, tradeType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdvancedVisible, setAdvancedVisible] = useState(false);
  const [latestSymbols, setLatestSymbols] = useState([]);
  const [ws, setWs] = useState(null);
  // const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [searchOptions, setSearchOptions] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { 
    selectedSymbol,
    setSelectedSymbol,
    marketData,
    isModifyingOrder,
    orderToModify,
    cancelOrderModification,
    wsConnected
  } = useWebTrader();

  const symbolMarketData = selectedSymbol?.conid ? marketData[selectedSymbol.conid] : null;


  const tradeTypeLabels = {
    stocks: 'EQ',
    futures: 'FU',
    forex: 'FX',
    options: 'OPT'
  };


  // Load latest symbols from localStorage when the component mounts
  useEffect(() => {
    const storedSymbols = localStorage.getItem(`latestSymbols_${tradeType}`);
    if (storedSymbols) {
      setLatestSymbols(JSON.parse(storedSymbols));
    }
  }, [tradeType]);

  // Function to update the latest symbols in localStorage
  const updateLatestSymbols = (selectedSymbol) => {
    let updatedSymbols = [...latestSymbols];
    const exists = updatedSymbols.find((symbol) => symbol.conid === selectedSymbol.conid);

    if (!exists) {
      updatedSymbols = [selectedSymbol, ...updatedSymbols.slice(0, 4)];
      setLatestSymbols(updatedSymbols);
      localStorage.setItem(`latestSymbols_${tradeType}`, JSON.stringify(updatedSymbols));
    }
  };

  // Function to fetch symbols from API
  const fetchSymbols = async (searchQuery) => {
    if (!searchQuery?.trim()) {
      setSearchOptions([]);
      return;
    }
    setSearchOptions([]);

    setIsLoading(true);
    try {
      const response = await authAxios.get(`/broker/interactive_brokers/search/stocks/`, {
        params: {
          search: searchQuery
        }
      });
      
      const apiSymbols = response.data.map(item => ({
        label: item.ticker,
        name: item.name,
        currency: item.currency,
        exchange: item.listingExchange,
        conid: item.conid
      }));
      
      setSearchOptions(apiSymbols);
    } catch (error) {
      console.error('Error fetching symbols:', error);
      
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  // Modify initial values to use orderToModify data when in modification mode
  const initialValues = isModifyingOrder ? {
    symbol: {
      label: orderToModify.data.symbol,
      conid: orderToModify.data.conid,
      name: orderToModify.coin_info.company_name,
      exchange: orderToModify.coin_info.listing_exchange,
      currency: orderToModify.coin_info.currency
    },
    side: orderToModify.data.side,
    order_type: orderToModify.data.order_type,
    quantity: orderToModify.data.quantity,
    price: orderToModify.data.price || '',
    tif: orderToModify.data.tif.toLowerCase(),
    stop_loss: '',
    profit_target: '',
    order_comment: '',
    isStopLossEnabled: false,
    isProfitTargetEnabled: false,
  } : {
    symbol: null,
    side: 'BUY',
    order_type: 'MKT',
    quantity: 0,
    price: '',
    tif: 'day',
    stop_loss: '',
    profit_target: '',
    order_comment: '',
    isStopLossEnabled: false,
    isProfitTargetEnabled: false,
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    symbol: Yup.object().required('Equity symbol is required'),
    quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
    price: Yup.number().when('order_type', {
      is: (val) => val !== 'MKT',
      then: () => Yup.number().required('Price is required').min(0.01, 'Price must be greater than 0'),
      otherwise: () => Yup.number(),
    }),
    order_type: Yup.string().required('Order Type is required'),
    stop_loss: Yup.number().when(['isStopLossEnabled', 'side', 'order_type'], {
      is: (isEnabled, side, orderType) => isEnabled === true,
      then: () => Yup.number()
        .required('Stop Loss is required')
        .min(0.01, 'Stop Loss must be greater than 0')
        .test('stop-loss-validation', 'Invalid Stop Loss price', function(value) {
          const { side, order_type, price } = this.parent;
          
          if (!value) return true; // Skip validation if no stop loss value
          
          // Use market price for MKT orders, limit price for LMT orders
          const referencePrice = order_type === 'MKT' 
            ? symbolMarketData?.bid_price 
            : parseFloat(price);
            
          if (!referencePrice) return true; // Skip if no reference price available

          if (side === 'BUY') {
            if (value >= referencePrice) {
              return this.createError({
                message: `Stop Loss must be below the ${order_type === 'MKT' ? 'market' : 'limit'} price for BUY orders`
              });
            }
          } else if (side === 'SELL') {
            if (value <= referencePrice) {
              return this.createError({
                message: `Stop Loss must be above the ${order_type === 'MKT' ? 'market' : 'limit'} price for SELL orders`
              });
            }
          }
          return true;
        }),
      otherwise: () => Yup.number().nullable(),
    }),
    profit_target: Yup.number().when(['isProfitTargetEnabled', 'side', 'order_type'], {
      is: (isEnabled, side, orderType) => isEnabled === true,
      then: () => Yup.number()
        .required('Profit Target is required')
        .min(0.01, 'Profit Target must be greater than 0')
        .test('take-profit-validation', 'Invalid Take Profit price', function(value) {
          const { side, order_type, price } = this.parent;
          
          if (!value) return true; // Skip validation if no take profit value
          
          // Use market price for MKT orders, limit price for LMT orders
          const referencePrice = order_type === 'MKT' 
            ? symbolMarketData?.bid_price 
            : parseFloat(price);
            
          if (!referencePrice) return true; // Skip if no reference price available

          if (side === 'BUY') {
            if (value <= referencePrice) {
              return this.createError({
                message: `Take Profit must be above the ${order_type === 'MKT' ? 'market' : 'limit'} price for BUY orders`
              });
            }
          } else if (side === 'SELL') {
            if (value >= referencePrice) {
              return this.createError({
                message: `Take Profit must be below the ${order_type === 'MKT' ? 'market' : 'limit'} price for SELL orders`
              });
            }
          }
          return true;
        }),
      otherwise: () => Yup.number().nullable(),
    }),
    stopLossEnabled: Yup.boolean(),
    profitTargetEnabled: Yup.boolean(),
    order_comment: Yup.string().notRequired(),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const orderData = {
        conid: values.symbol.conid,
        symbol: values.symbol.label,
        side: values.side,
        order_type: values.order_type,
        quantity: values.quantity,
        price: values.order_type === 'MKT' ? null : values.price,
        tif: values.tif,
        stop_loss: values.isStopLossEnabled ? values.stop_loss : null,
        profit_target: values.isProfitTargetEnabled ? values.profit_target : null,
        order_comment: values.order_comment,
      };

      if (isModifyingOrder) {
        const response = await dispatch(updateOrder({
          strategyId,
          orderId: orderToModify.id,
          orderData: orderData
        })).unwrap();
        enqueueSnackbar('Order modified successfully', { variant: 'success' });
        cancelOrderModification();
      } else {
        await dispatch(createOrder({ 
          strategyId, 
          orderData: orderData 
        })).unwrap();
        
        // Format the notification message
        const orderDetails = `${values.side} ${values.quantity} ${values.symbol.label} @ ${values.order_type} ${values.tif.toUpperCase()}`;
        const message = (
          <Box>
            <Typography variant="subtitle2">Order Placed</Typography>
            <Typography variant="body2">{orderDetails}</Typography>
            <Typography variant="caption" sx={{ display: 'block' }}>Strategy ID: {strategyId}</Typography>
            <Typography variant="caption" sx={{ display: 'block' }}>{new Date().toLocaleString()}</Typography>
          </Box>
        );
        
        enqueueSnackbar(message, { 
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          autoHideDuration: 5000
        });
        resetForm();
        setSelectedSymbol(null); // Reset the selected symbol to clear market data display
        setSearchOptions([]); // Clear search options
        setSearchText(''); // Clear search text
      }

      // Refresh orders list after successful submission
      dispatch(fetchOrders({ strategyId, refresh: true }));
      dispatch(fetchTrades({ strategyId }));
    } catch (error) {
      console.error('Order submission failed:', error);
    } finally {
      setSubmitting(false);
    }
  };

  // Cleanup effect when component unmounts
  useEffect(() => {
    return () => {
      setSelectedSymbol(null); // Reset selectedSymbol on unmount
      // setLastPrice('N/A');
      // setChangePercent('N/A');
      // setVolume('N/A');
    };
  }, [setSelectedSymbol]);

  // Update button text based on modification state
  const getButtonText = (side) => {
    if (isModifyingOrder) {
      return 'Modify Order';
    }
    console.log(side);
    return side === 'BUY' ? 'Place Buy Order' : 'Place Sell Order';
  };

  // First, update the useEffect to close advanced tab when modifying order
  useEffect(() => {
    if (isModifyingOrder) {
      setAdvancedVisible(false);
    }
  }, [isModifyingOrder]);

  return (
    <>
      <Formik 
        initialValues={initialValues} 
        validationSchema={validationSchema} 
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, errors, touched }) => (
          <Form>
            {isModifyingOrder && (
              <Card variant="elevation" elevation={2} sx={{ p: 1 }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    MODIFYING ORDER - {orderToModify.id}
                  </Typography>
                  <IconButton 
                    size="small" 
                    onClick={cancelOrderModification}
                    sx={{ ml: 1 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Card>
            )}
            <Box>
              {/* Search Section */}
              <Box sx={{ mt: 2 }}>
                {!isModifyingOrder ? (
                  <>
                    <Field
                      component={Autocomplete}
                      name="symbol"
                      options={searchOptions}
                      loading={isLoading}
                      getOptionLabel={(option) => option.label}
                      popupIcon={<SearchIcon />}
                      sx={{
                        '.MuiIconButton-root': {
                          transform: 'none!important',
                        },
                      }}
                      isOptionEqualToValue={(option, value) => option.label === value?.label}
                      filterOptions={(x) => x}
                      onInputChange={(event, value, reason) => {
                        setSearchText(value);
                        setSearchOptions([]);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && searchText) {
                          event.preventDefault();
                          
                          fetchSymbols(searchText);
                          
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Chip
                            label={tradeTypeLabels[tradeType]}
                            color="warning"
                            size="small"
                            sx={{ borderRadius: '4px', mr: 2 }}
                          />
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="body2" fontWeight="bold">
                              {option.name}
                            </Typography>
                            <Typography variant="caption">
                              {option.currency} • {option.label}
                            </Typography>
                          </Box>
                          <Chip 
                            label={option.exchange} 
                            size="small" 
                            sx={{ ml: 2, fontSize: '0.75rem' }} 
                          />
                        </Box>
                      )}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          name="symbol"
                          error={touched['symbol'] && !!errors['symbol']}
                          helperText={
                            isTyping && searchText 
                              ? 'Press Enter to search' 
                              : (touched['symbol'] && errors['symbol'])
                          }
                          label="Equity symbol"
                          placeholder="Type and press Enter to search"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          size="small"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setFieldValue('symbol', newValue);
                        setSelectedSymbol(newValue);
                        if (newValue) {
                          updateLatestSymbols(newValue);
                        }
                        setSearchOptions([]);
                        setSearchText('');
                      }}
                    />
                    {/* LATEST Section */}
                    <Typography variant="caption" display="block" sx={{ mt: 1, minHeight: 20 }}>
                      LATEST:&nbsp;
                      <Box sx={{ml:6, mt:-3}}>
                      {latestSymbols.map((symbol) => (
                        <Chip
                          key={symbol.conid}
                          label={`${symbol.label} • ${symbol.exchange}`}
                          onClick={() => {
                            setSelectedSymbol(symbol);
                            setFieldValue('symbol', symbol);
                          }}
                          size="small"
                          sx={{ 
                            fontSize: '0.75rem', 
                            borderRadius: '4px', 
                            marginRight: '4px', 
                            marginTop: '4px',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'action.hover',
                            },
                          }}
                        />
                        
                      ))}</Box>
                    </Typography>
                  </>
                ) : null}
              </Box>

              {/* Stock Info Section */}
              <Card variant="outlined" sx={{ mt: 2, mb: 4, p: 0, opacity: values.symbol ? 1 : 0.5 }}>
                <CardContent sx={{ p: '8px!important' }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Chip
                      label={tradeTypeLabels[tradeType]}
                      color= {values.symbol ? 'warning' : 'default'}
                      size="medium"
                      sx={{ borderRadius: '4px', mr: 1 }}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight="bold">
                        {values.symbol ? values.symbol.name : 'SYMBOL NAME'}
                      </Typography>
                      <Typography variant="caption">
                        {values.symbol ? `${values.symbol.label} • ${values.symbol.currency}` : 'SYMBOL • USD'}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider />

                  <Grid container justifyContent="space-between" alignItems="center" my={1}>
                    <Grid item>
                      <Typography variant="body2" fontWeight="bold">
                        {symbolMarketData?.volume || 'N/A'}
                      </Typography>
                      <Typography variant="caption">Volume</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontWeight="bold">
                        {symbolMarketData?.bid_price || 'N/A'}
                      </Typography>
                      <Typography variant="caption">Last Price</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontWeight="bold">
                        {symbolMarketData?.change_percent || 'N/A'}
                      </Typography>
                      <Typography variant="caption">%Change</Typography>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container justifyContent="space-between" alignItems="center" mt={1}>
                    <Grid item>
                      <Box display="flex" alignItems="center" gap={0.5}>
                      {wsConnected && (
                          <AccessTimeIcon 
                            sx={{ 
                              fontSize: 14,
                              color: 'default.main'
                            }} 
                          />
                        )}
                        <Typography variant="caption">
                          {wsConnected ? "Realtime" : "N/A"}
                        </Typography>
                        
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" textAlign="right">
                        Exchange: {values.symbol ? values.symbol.exchange : 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* Action (Buy/Sell) Toggle */}
              <ToggleButtonGroup
                value={values.side}
                exclusive
                onChange={(event, newSide) => {
                  if (newSide !== null && !isModifyingOrder) {
                    setFieldValue('side', newSide);
                  }
                }}
                fullWidth
                disabled={isModifyingOrder}
              >
                <ToggleButton value="BUY" sx={{ flexGrow: 1, color: values.side === 'buy' ? 'green' : 'inherit' }}>
                  BUY
                </ToggleButton>
                <ToggleButton value="SELL" sx={{ flexGrow: 1, color: values.side === 'sell' ? 'red' : 'inherit' }}>
                  SELL
                </ToggleButton>
              </ToggleButtonGroup>

              {/* Order Form Fields */}
              <Grid container spacing={2} mt={2}>
                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    select
                    name="order_type"
                    label="Order Type"
                    size="small"
                    fullWidth
                    disabled={isModifyingOrder}
                  >
                    <MenuItem value="MKT">Market</MenuItem>
                    <MenuItem value="LMT">Limit</MenuItem>
                    <MenuItem value="STP">Stop</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    name="quantity"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    fullWidth
                    size="small"
                    inputProps={{ min: 1, step: 1 }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    name="price"
                    label="Price"
                    type="number"
                    fullWidth
                    size="small"
                    inputProps={{ min: 0.01, step: 0.01 }}
                    disabled={values.order_type === 'MKT'}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    name="tif"
                    label="TIF"
                    select
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="gtc">GTC</MenuItem>
                  </Field>
                </Grid>
              </Grid>

              {/* Order Summary Text */}
              <Box mt={2}>
                <Card variant="outlined">
                  <Typography variant="body1" textAlign="center">
                    Order = {values.side?.toUpperCase()} {values.quantity || 'N/A'} {values.symbol?.label || 'N/A'} @ {values.price || 'N/A'} {values.order_type} {values.tif}
                  </Typography>
                </Card>
              </Box>

              {/* Buttons */}
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <Button 
                    variant="outlined" 
                    fullWidth 
                    onClick={() => setAdvancedVisible(!isAdvancedVisible)}
                    disabled={isModifyingOrder}
                  >
                    Advanced
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: values.side === 'BUY' ? 'green' : 'red',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: values.side === 'BUY' ? '#005500' : '#880000',
                      },
                    }}
                    disabled={!values.symbol || isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      getButtonText(values.side)
                    )}
                  </Button>
                </Grid>
              </Grid>

              {/* Advanced Section */}
              {isAdvancedVisible && (
                <Box mt={2}>
                  <Card variant="outlined" sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Field
                              as={Checkbox}
                              name="isStopLossEnabled"
                              checked={values.isStopLossEnabled}
                              onChange={(event) => setFieldValue('isStopLossEnabled', event.target.checked)}
                              disabled={isModifyingOrder}
                            />
                          }
                          label="Stop Loss"
                        />
                        <Field
                          component={TextField}
                          name="stop_loss"
                          label="Stop Loss"
                          type="number"
                          fullWidth
                          size="small"
                          inputProps={{ step: 0.01 }}
                          disabled={!values.isStopLossEnabled || isModifyingOrder}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Field
                              as={Checkbox}
                              name="isProfitTargetEnabled"
                              checked={values.isProfitTargetEnabled}
                              onChange={(event) => setFieldValue('isProfitTargetEnabled', event.target.checked)}
                              disabled={isModifyingOrder}
                            />
                          }
                          label="Profit Target"
                        />
                        <Field
                          component={TextField}
                          name="profit_target"
                          label="Profit Target"
                          type="number"
                          fullWidth
                          size="small"
                          inputProps={{ step: 0.01 }}
                          disabled={!values.isProfitTargetEnabled || isModifyingOrder}
                        />
                      </Grid>
                    </Grid>

                    <Box mt={2}>
                      <Field
                        component={TextField}
                        name="order_comment"
                        label="Order Comment"
                        multiline
                        fullWidth
                        size="small"
                        disabled={isModifyingOrder}
                      />
                    </Box>
                  </Card>
                </Box>
              )}

              {/* Summary Section */}
              <Box mt={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2">Buying Power</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">N/A</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2">Required Margin</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">N/A</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2">Commission (estimated)</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">N/A</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" fontWeight="bold">Total Amount (estimated)</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2" fontWeight="bold">N/A</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TradePanel;